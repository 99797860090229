/*==========================================================================

	Template Elements Stylesheet
	____________________________________________________________

	Client:		Template 4
	Date:		30th November 2017
	Author:		Edge Marketing Solutions

===========================================================================*/

	/* [Global Variables] - http://sass-lang.com/guide */

	$color-default: rgb(85, 98, 111);
	$color-primary: rgb(236, 1, 127);
	$color-secondary: rgb(37, 169, 224);
	$color-tertiary: rgb(41, 180, 115);
	$color-fourth: rgb(246, 142, 30);

	$font-default: 'Open Sans', sans-serif;
	$font-primary: 'Roboto Slab', serif;

/*=========================================================================*/

	/* [Global Template Styles] */

	body
	{
		color: $color-default;
		font-family: $font-default;
		font-size: 0.9rem;
		text-align: justify;
	}

	a
	{
		-webkit-transition: all 150ms ease-in-out;
		-moz-transition: all 150ms ease-in-out;
		-ms-transition: all 150ms ease-in-out;
		-o-transition: all 150ms ease-in-out;
		transition: all 150ms ease-in-out;

		color: $color-primary;
	}

		a:hover
		{
			color: $color-secondary;
		}

	h1
	{
		margin: 0 0 30px 0;

		color: $color-primary;
		font-family: $font-primary;
		font-size: 3rem;
	}

	h2
	{
		margin: 30px 0;

		color: $color-primary;
		font-family: $font-primary;
		font-size: 2.2rem;
	}

	h3
	{
		margin: 30px 0;

		color: $color-secondary;
		font-family: $font-primary;
		font-size: 2.2rem;
	}

	h4
	{
		margin: 30px 0;

		color: $color-default;
		font-family: $font-primary;
		font-size: 2.2rem;
	}

	h5
	{
		margin: 30px 0 6px 0;

		color: $color-primary;
		font-family: $font-primary;
		font-size: 1.3rem;
		font-weight: 700;
	}

	h6
	{
		margin: 30px 0 6px 0;

		color: $color-secondary;
		font-family: $font-primary;
		font-size: 1.3rem;
		font-weight: 700;
	}

	img
	{
		max-width: 100%;
	}

	img[data-size-ratio^="0"],
	.clear-height
	{
		background: #ffffff url(../images/loading.gif) no-repeat 50% 50%;
	}

	img.svg-responsive
	{
		width: 100% \9;
	}

	/*OPTIONAL FULL WIDTH NAVIGATION - Add nav-justified to <ul class="nav navbar-nav">*/
	.navbar-nav.nav-justified > li {
		float: none!important;
	}

	/* Generated by Glyphter (http://www.glyphter.com) on Tue Sep 06 2016*/
	@font-face {
		font-family: 'social icons';
		src: url('../fonts/social-icons.eot');
		src: url('../fonts/social-icons.eot?#iefix') format('embedded-opentype'),
		url('../fonts/social-icons.woff') format('woff'),
		url('../fonts/social-icons.ttf') format('truetype'),
		url('../fonts/social-icons.svg#social-icons') format('svg');
		font-weight: normal;
		font-style: normal;
	}
	[class^="social-icon-"]::before, [class*=" social-icon-"]::before {
		display: inline-block;
		font-family: 'social icons';
		font-style: normal;
		font-weight: normal;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.social-icon-square-facebook::before {content:'\0041';}
	.social-icon-square-google-plus::before {content:'\0042';}
	.social-icon-square-linkedin::before {content:'\0043';}
	.social-icon-square-pinterest::before {content:'\0044';}
	.social-icon-square-twitter::before {content:'\0045';}
	.social-icon-square-youtube::before {content:'\0046';}
	.social-icon-circle-facebook::before {content:'\0047';}
	.social-icon-circle-google-plus::before {content:'\0048';}
	.social-icon-circle-linkedin::before {content:'\0049';}
	.social-icon-circle-pinterest::before {content:'\004a';}
	.social-icon-circle-twitter::before {content:'\004b';}
	.social-icon-circle-youtube::before {content:'\004c';}
	.social-icon-rounded-facebook::before {content:'\004d';}
	.social-icon-rounded-google-plus::before {content:'\004e';}
	.social-icon-rounded-linkedin::before {content:'\004f';}
	.social-icon-rounded-pinterest::before {content:'\0050';}
	.social-icon-rounded-twitter::before {content:'\0051';}
	.social-icon-rounded-youtube::before {content:'\0052';}

	[class^="social-icon-circle-"]::before, [class*=" social-icon-circle-"]::before {
		border-radius: 100%;
	}

	[class^="social-icon-rounded-"]::before, [class*=" social-icon-rounded-"]::before {
		border-radius: 15%;
	}

	.swiper-container
	{
		width: 100%;

		.approved-logo
		{
			position: absolute;
			z-index: 250;
			bottom: 100px;

			img {
			  width: 100%;
			}
		}
	}

	.btn-primary
	{
		background-color: $color-secondary;
		border: none;
		cursor: pointer;
	}

	.btn-danger
	{
		background-color: $color-primary;
		border: none;
		cursor: pointer;
	}

		.btn-danger:hover
		{
			background-color: $color-secondary;
		}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {

	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {

		//Bootstrap - hide only mobile
		.d-xs-none
		{
			display: none;
		}
	}

	/*=========================================================================*/

	/* [Header Template Styles] */

	.header
	{
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		z-index: 2000;
		padding: 27px 0;

		background-color: rgba(255, 255, 255, 0.8);
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (max-width: 991px) {
		.header
		{
			position: relative;
			padding: 27px 0;
		}

		.header .h-logo
		{
			text-align: center;
		}

		.header img
		{
			max-width: 300px;
		}
	}

	/*=========================================================================*/

	/* [Navigation Template Styles] */

	.navbar
	{
		padding: 0;
	}

	.navbar-nav
	{
		width: 100%;
		justify-content: flex-end;
		align-items: center;
	}

	.navbar-light .navbar-nav .nav-link
	{
		padding: 1rem 1.5rem;
		position: relative;

		color: $color-primary;
		font-size: 1.0rem;
	}

	.navbar-light .navbar-nav .show > .nav-link,
	.navbar-light .navbar-nav .active > .nav-link
	{
		color: $color-primary;
	}

	.navbar-light .navbar-nav .show > .nav-link::before,
	.navbar-light .navbar-nav .active > .nav-link::before
	{
		content: ' ';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 3px;

		background-color: $color-primary;
	}

	.navbar-light .navbar-nav .nav-link:focus,
	.navbar-light .navbar-nav .nav-link:hover
	{
		color: $color-secondary;
	}

	.dropdown-item
	{
		padding: 0.25rem 0.25rem;
		text-align: center;
	}

	.navbar-light .navbar-toggler
	{
		width: 100%;
	}

	/* Large desktop */
	@media (min-width: 1200px) {
		.navbar-nav .dropdown-menu
		{
			left: 50%;
			transform: translateX(-50%);
		}
	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {
		.navbar-light .navbar-nav .nav-link
		{
			padding: 1rem 1.0rem;

			font-size: 0.9rem;
		}

		.navbar-nav .dropdown-menu
		{
			left: 50%;
			transform: translateX(-50%);
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.navbar-light .navbar-nav .nav-link
		{
			padding: 1rem 1.0rem;

			font-size: 0.9rem;
		}

		.navbar-nav
		{
			margin-top: 20px;
			justify-content: center;
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.navbar
		{
			margin-top: 20px;
			display: block;
		}

		.navbar-nav
		{
			display: block;
		}

		.navbar-light .nav-item
		{
			text-align: center;
		}

		.navbar-light .nav-link
		{
			width: 100%;

			text-align: center;
		}
	}

	/*=========================================================================*/

	/* [Page Template Styles] */

	.highlight-panel
	{
		padding: 45px 0;

		background-color: $color-secondary;
		border-bottom: 5px solid rgb(255, 255, 255);

		color: rgb(255, 255, 255);
		text-align: center;
		font-size: 1.3rem;
	}

		.highlight-panel a
		{
			color: rgb(255, 255, 255);
		}

		.highlight-panel h3
		{
			margin: 10px 0 15px 0;
			position: relative;

			color: rgb(255, 255, 255);
			font-size: 3rem;
		}

		.highlight-panel h3::before
		{
			content: ' ';
			position: absolute;
			top: -90px;
			left: 50%;
			transform: translateX(-50%);
			width: 71px;
			height: 68px;

			background-image: url(../images/centre-icon.svg);
		}

		.highlight-panel .btn-info
		{
			margin-top: 10px;

			border: 2px solid rgb(255, 255, 255);
			background-color: transparent;
		}

	.page
	{
		width: 100%;
		padding: 60px 0 90px;
	}

	.feature-nav
	{
		width: 100%;
		display: flex;
	}

		.feature-nav .item
		{
			width: 33.3333%;
			padding: 50px 60px;
			position: relative;
			z-index: 1;

			border-top: 8px solid;
			border-bottom: 8px solid;

			text-align: center;
		}

			.feature-nav .item::before
			{
				position: absolute;
				content: ' ';
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				z-index: -1;

				background-color: rgba(255, 255, 255, 0.9);
			}

		.feature-nav .item h2
		{
			margin: 0 0 15px 0;

			font-size: 2rem;
		}

		.feature-nav .item:nth-child(1)
		{
			border-color: $color-tertiary;
		}


		.feature-nav .item:nth-child(2)
		{
			border-color: $color-secondary;
		}


		.feature-nav .item:nth-child(3)
		{
			border-color: $color-fourth;
		}

	.staff-profile
	{
		padding-top: 30px;
	}

	.staff-profile h2
	{
		margin: 0 0 15px 0;

		font-size: 2.0rem;
	}

	.subpage-banner
	{
		width: 100%;
		height: 550px;
		position: relative;

		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}

	.subpage-banner::before
	{
		position: absolute;
		content: ' ';
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;

		background : -moz-linear-gradient(50% 105.9% 90deg,rgba(255, 255, 255, 1) 0%,rgba(35, 31, 32, 1) 100%);
		background : -webkit-linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(35, 31, 32, 1) 100%);
		background : -webkit-gradient(linear,50% 105.9% ,50% 5.5% ,color-stop(0,rgba(255, 255, 255, 1) ),color-stop(1,rgba(35, 31, 32, 1) ));
		background : -o-linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(35, 31, 32, 1) 100%);
		background : -ms-linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(35, 31, 32, 1) 100%);
		-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF', endColorstr='#231F20' ,GradientType=0)";
		background : linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(35, 31, 32, 1) 100%);
		opacity : 0.4;
		filter: alpha(opacity=40) progid:DXImageTransform.Microsoft.Alpha(opacity=40) progid:DXImageTransform.Microsoft.gradient(startColorstr='#231F20',endColorstr='#FFFFFF' , GradientType=0);
	}

		.subpage-banner .page-title
		{
			width: 100%;
			position: absolute;
			bottom: 0;
		}

		.subpage-banner .page-title h1
		{
			margin: 0;
			display: inline-block;
			padding: 0.5rem 1.5rem;

			background-color: $color-primary;

			color: rgb(255, 255, 255);
			font-size: 2.5rem;
		}

	.rotator
	{
		width: 100%;
		position: relative;
		z-index: 100;
	}

	.rotator .block-style
	{
		bottom: -70px;
		right: 40px;
		z-index: 1000;
	}

	.rotator img
	{
		width: 100%;
	}

	.swiper-pagination-fraction,
	.swiper-pagination-custom,
	.swiper-container-horizontal > .swiper-pagination-bullets
	{
		bottom: 40px;
		padding-right: 45px;

		text-align: right;
	}

	.swiper-pagination-bullet
	{
		width: 18px;
		height: 18px;

		background: transparent;
		border: 1px solid rgb(255, 255, 255);
		opacity: 1;
	}

	.swiper-pagination-bullet-active
	{
		background-color: $color-primary;
	}

	.swiper-slide-image
	{
		position: relative;
		z-index: 1;
	}

	.swiper-slide-image::before
	{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: ' ';
		z-index: 100;

		background : -moz-linear-gradient(50% 78.02% 90deg,rgba(255, 255, 255, 1) 0%,rgba(35, 31, 32, 1) 100%);
		background : -webkit-linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(35, 31, 32, 1) 100%);
		background : -webkit-gradient(linear,50% 78.02% ,50% 4.66% ,color-stop(0,rgba(255, 255, 255, 1) ),color-stop(1,rgba(35, 31, 32, 1) ));
		background : -o-linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(35, 31, 32, 1) 100%);
		background : -ms-linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(35, 31, 32, 1) 100%);
		-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF', endColorstr='#231F20' ,GradientType=0)";
		background : linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(35, 31, 32, 1) 100%);
		opacity : 0.4;
		filter: alpha(opacity=40) progid:DXImageTransform.Microsoft.Alpha(opacity=40) progid:DXImageTransform.Microsoft.gradient(startColorstr='#231F20',endColorstr='#FFFFFF' , GradientType=0);
	}

	.swiper-slide .swiper-text
	{
		position: absolute;
		width: 100%;
		bottom: 40px;
		left: 0;
		z-index: 2000;
	}

	.swiper-slide .title
	{
		position: relative;
		width: 100%;

		font-size: 3rem;
		color: white;
		text-align: center;
		font-weight: 300;
		text-shadow: 3px 3px 10px #333333;
	}

	.swiper-slide .subtitle
	{
		width: 100%;

		font-size: 28px;
		color: white;
		text-align: left;
		font-weight: 300;
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {
		.feature-nav .item
		{
			padding: 30px 40px;
		}

		.subpage-banner
		{
			height: 450px;
		}

		.swiper-slide .swiper-text
		{
			bottom: 30px;
		}

		.swiper-slide .title
		{
			font-size: 2.5rem;
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.feature-nav .item
		{
			padding: 30px 20px;

			font-size: 0.8rem;
		}

		.feature-nav .item h2
		{
			font-size: 1.6rem;
		}

		.subpage-banner
		{
			height: 350px;
		}

		.swiper-slide .swiper-text
		{
			bottom: 25px;
		}

		.swiper-slide .title
		{
			font-size: 2.2rem;
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.page
		{
			padding: 30px 0;
		}

		.highlight-panel
		{
			font-size: 1rem;
		}

		.highlight-panel h3
		{
			font-size: 2rem;
		}

		.feature-nav
		{
			width: 100%;
			display: block;
		}

		.feature-nav .item
		{
			width: 100%;
			padding: 30px 20px;

			border-top: none;

			font-size: 0.8rem;
		}

			.feature-nav .item h2
			{
				font-size: 1.6rem;
			}

			.feature-nav .item .btn
			{
				font-size: 0.8rem;
			}

		.subpage-banner
		{
			height: 225px;
		}

		.swiper-slide .swiper-text
		{
			bottom: 50%;
			transform: translateY(50%);
		}

		.swiper-slide .title
		{
			font-size: 2.0rem;
		}
	}

	/*=========================================================================*/

	/* [Footer Template Styles] */

	.footer
	{
		width: 100%;
		padding: 50px 0;

		background-color: $color-primary;

		color: rgb(255, 255, 255);
		font-size: 0.8rem;
	}

		.footer a
		{
			color: rgb(255, 255, 255);
		}

			.footer a:hover
			{
				color: $color-secondary;
				text-decoration: none;
			}

		.footer h6
		{
			margin: 0 0 10px 0;

			font-size: 0.8rem;
			color: rgb(255, 255, 255);
		}

		.footer ul
		{
			margin: 0 0 40px 0;
			padding: 0;

			list-style-type: none;
			border-top: 1px solid rgba(255, 255, 255, 0.3);
		}

			.footer ul > li
			{
				padding: 5px 0;

				border-bottom: 1px solid rgba(255, 255, 255, 0.3);
			}
		
		.footer .members
		{
			text-align: right;
		}

		.footer .social
		{
			font-size: 2rem;
		}

		.footer .footnotes
		{
			opacity: 0.4;
		}

		.footer .btn
		{
			margin-bottom: 30px;
		}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.footer
		{
			padding: 30px 0;

			text-align: center;
		}

			.footer .members
			{
				text-align: center;
			}

			.footer .footnotes
			{
				margin-top: 40px;
			}
	}

	/*=========================================================================*/

	/* [Table as row override Styles] */

	table.table-as-row > tbody > tr:before,
	table.table-as-row > tbody > tr:after {
		content: " ";
		display: table;
		clear: both;
	}

	table.table-as-row > tbody > tr {
		height: auto !important;
	}

	table.table-as-row,
	table.table-as-row > tbody,
	table.table-as-row > tbody > tr {
		display: block;
		width: auto;
	}

	table.table-as-row > tbody > tr {
		display: block;
		width: auto;
		margin-right: -15px;
		margin-left: -15px;
	}

	table.table-as-row > tbody > tr > td[class*=col-] {
		display: block;
		height: auto !important;
		margin-bottom: 20px;
	}

	/* col-lg */
	@media (min-width: 1200px) {
		table.table-as-row > tbody > tr > td[class*=col-lg-] {
			float: left;
		}
	}

	/* col-md */
	@media (min-width: 992px) {
		table.table-as-row > tbody > tr > td[class*=col-md-] {
			float: left;
		}
	}

	/* col-sm */
	@media (min-width: 768px) {
		table.table-as-row > tbody > tr > td[class*=col-sm-] {
			float: left;
		}
	}

	/* col-xs */
	table.table-as-row > tbody > tr > td[class*=col-xs-] {
		float: left;
	}